@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --text-single-200-regular: "DM Sans";
  --font-poppins: Poppins;

  /* font sizes */
  --text-single-200-regular-size: 18px;
  --font-size-base: 16px;
  --font-size-sm: 14px;

  /* Colors */
  --color-gray-100: #808080;
  --color-gray-200: #13294b;
  --neutral-colors-white: #fff;
  --color-orangered: #ff5f05;
  --color-black: #000;
  --color-dodgerblue: #4285f4;
  --neutral-colors-headings-black: #5d5a88;

  /* Gaps */
  --gap-5xs: 8px;

  /* Paddings */
  --padding-xs: 12px;
  --padding-lg: 18px;
  --padding-5xl: 24px;
  --padding-17xl: 36px;

  /* Border radiuses */
  --br-21xl: 40px;
  --br-4xs: 9px;
}
