.container,
.imageContainer {
  position: absolute;
  top: 0;
  left: 0;
}
.container {
  background-color: var(--neutral-colors-white);
  width: 1440px;
  height: 1749px;
}
.imageContainer {
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  border-radius: var(--br-11xl);
  background-color: #eef5ff;
}
.imagePlaceholder {
  position: absolute;
  height: 33.66%;
  width: 84.72%;
  top: 11.05%;
  right: 7.64%;
  bottom: 55.29%;
  left: 7.64%;
}
.groupChild,
.header2Inner {
  position: absolute;
  left: 0;
  width: 1440px;
  height: 117.9px;
}
.groupChild {
  top: 0;
  background-color: var(--neutral-colors-white);
}
.header2Inner {
  top: 0.1px;
}
.navListChild {
  width: 28px;
  position: relative;
  height: 0;
}
.events,
.home1 {
  position: relative;
  line-height: 18px;
}
.home1 {
  text-decoration: underline;
}
.events {
  color: var(--primary-blue);
}
.sections {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 33px;
}
.lineRoundedsearchIcon {
  width: 18px;
  position: relative;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.buttonText {
  position: relative;
  line-height: 18px;
}
.masterPrimaryButton,

.masterPrimaryButton {
  border-radius: var(--br-11xl);
  background-color: var(--primary-blue);
  padding: var(--padding-lg) var(--padding-5xl);
  gap: var(--gap-5xs);
  font-size: var(--text-single-100-bold-size);
  color: var(--neutral-colors-white);
}
.navList {
  position: absolute;
  top: 32.1px;
  left: 679px;
  width: 651px;
  padding: 0 0 0 25px;
  box-sizing: border-box;
  gap: 24px;
}
.header2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 1440px;
  height: 118px;
}
.connectingCommunities {
  margin: 0;
}
.heading,
.paragraph {
  position: absolute;
  left: 333px;
  display: inline-block;
  width: 804px;
}
.heading {
  top: 351px;
  font-size: 40px;
  line-height: 56px;
  color: var(--primary-blue);
}
.paragraph {
  top: 495px;
  line-height: 30px;
  margin-top: 40px;

}
.buttonSet,
.wrapper {
  position: absolute;
  top: 603px;
  left: 524.5px;
  width: 391px;
  height: 66px;
}
.wrapper {
  top: 0;
  left: 0;
  width: 1440px;
  height: 1749px;
  font-size: var(--text-single-200-bold-size);
}
.cupIcon,
.illinoisFightingIlliniLogoIcon {
  position: absolute;
  top: 50px;
  left: 60px;
  width: 39px;
  height: 56px;
  object-fit: cover;
}
.cupIcon {
  top: 571px;
  left: 822px;
  width: 329.7px;
  height: 329.7px;
  object-fit: contain;
}
.ideaLampIcon,
.noteAndPencil,
.pencilCupIcon {
  position: absolute;
  top: 555px;
  left: -20px;
  width: 414px;
  height: 414px;
  object-fit: cover;
}
.noteAndPencil,
.pencilCupIcon {
  top: 351px;
  left: 979px;
  width: 550px;
  height: 550px;
}
.noteAndPencil {
  top: 192px;
  left: 186px;
  width: 271px;
  height: 271px;
}
.heading1 {
  position: absolute;
  top: 972px;
  left: calc(50% - 171px);
  font-size: var(--headings-h2-size);
  line-height: 46px;
  color: var(--primary-blue);
}
.bigNumber {
  position: absolute;
  top: 96px;
  left: 49px;
  line-height: 50px;
}
.container1,
.title {
  position: absolute;
  left: 0;
}
.title {
  top: 150px;
  font-size: var(--headings-h3-size);
  line-height: 34px;
  color: var(--primary-blue);
}
.container1 {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: var(--br-xl);
  background-color: var(--primary-blue);
}
.imageIcon {
  position: absolute;
  height: 39.17%;
  width: 44.44%;
  top: 30.42%;
  right: 27.78%;
  bottom: 30.42%;
  left: 27.78%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.imagePlaceholder1 {
  position: absolute;
  height: 39.13%;
  width: 43.9%;
  top: 0;
  right: 27.74%;
  bottom: 60.87%;
  left: 28.35%;
}
.statInfo {
  position: absolute;
  top: 1089px;
  left: 142px;
  width: 164px;
  height: 184px;
}
.bigNumber1 {
  position: absolute;
  top: 96px;
  left: 0;
  line-height: 50px;
}
.imagePlaceholder2 {
  position: absolute;
  height: 39.13%;
  width: 32.58%;
  top: 0;
  right: 33.71%;
  bottom: 60.87%;
  left: 33.71%;
}
.statInfo1 {
  position: absolute;
  top: 1089px;
  left: 448px;
  width: 221px;
  height: 184px;
}
.title2 {
  position: absolute;
  top: 150px;
  left: 6px;
  font-size: var(--headings-h3-size);
  line-height: 34px;
  color: var(--primary-blue);
}
.imagePlaceholder3 {
  position: absolute;
  height: 39.13%;
  width: 48.98%;
  top: 0;
  right: 25.85%;
  bottom: 60.87%;
  left: 25.17%;
}
.statInfo2 {
  position: absolute;
  top: 1089px;
  left: 780px;
  width: 147px;
  height: 184px;
}
.title3 {
  position: absolute;
  top: 150px;
  left: 23.5px;
  font-size: var(--headings-h3-size);
  line-height: 34px;
  color: var(--primary-blue);
}
.imagePlaceholder4 {
  position: absolute;
  height: 39.13%;
  width: 25.44%;
  top: 0;
  right: 37.28%;
  bottom: 60.87%;
  left: 37.28%;
}
.statInfo3 {
  position: absolute;
  top: 1089px;
  left: 1015.5px;
  width: 283px;
  height: 184px;
}
.home {
  width: 100%;
  position: relative;
  background-color: var(--neutral-colors-white);
  height: 1000px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-13xl);
  color: var(--primary-orange);
  font-family: var(--text-single-200-bold);
}
