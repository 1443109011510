.events {
    padding: var(--padding-17xl);
  }
  
  .venues {
    padding: var(--padding-17xl);
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--padding-5xl);
  }
  
  .logo img {
    height: 50px;
  }
  
  .navList {
    display: flex;
    align-items: center;
  }
  
  .sections {
    display: flex;
    gap: var(--gap-5xs);
  }

  .navLink_active{
    color: var(--color-orangered);
  }
  
  .navLink {
    color: var(--color-gray-200);
    text-decoration: none;
    font-size: var(--font-size-base);
    font-weight: bold;
  }
  
  .searchAndCreate {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--padding-5xl);
  }
  
  .searchBar {
    padding: var(--padding-xs);
    border-radius: var(--br-4xs);
    border: 2px solid var(--color-orangered);
    background-color: var(--neutral-colors-white);
    color: var(--color-gray-200);
    font-size: var(--font-size-base);
    flex: 1;
    margin-right: var(--padding-xs);
  }
  
  .createButton {
    padding: var(--padding-xs) var(--padding-lg);
    border-radius: var(--br-21xl);
    background-color: var(--color-orangered);
    color: var(--neutral-colors-white);
    font-size: var(--font-size-base);
    font-weight: bold;
    border: none;
    cursor: pointer;
  }
  
  .OrganizationList {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: var(--gap-5xs);
  }
  

.eventCard {
    padding: var(--padding-lg);
    background-color: #eef5ff;
    border-radius: var(--br-4xs);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .updateButton {
    position: absolute;
    top: var(--padding-xs);
    right: var(--padding-xs);
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .updateButton img {
    height: 20px;
  }
  
  .formOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .formContainer {
    background-color: var(--neutral-colors-white);
    padding: var(--padding-5xl);
    border-radius: var(--br-4xs);
    width: 500px;
    max-width: 90%;
    position: relative;
  }
  .form {
    display: grid;
    gap: var(--padding-xs);
  }
  
  .formField {
    display: grid;
    /* gap: var(--padding-xs); */
  }
  
  .formField label {
    font-weight: bold;
    color: var(--color-gray-200);
  }
  
  .formField input {
    /* padding: var(--padding-xs); */
    border: 1px solid var(--color-gray-100);  border-radius: var(--br-4xs);
    font-size: var(--font-size-base);
    color: var(--color-gray-200);
  }
  
  .formField input:focus {
    outline: none;
    border-color: var(--color-orangered);
  }
  
  .submitButton {
    padding: var(--padding-xs) var(--padding-lg);
    border-radius: var(--br-21xl);
    background-color: var(--color-orangered);
    color: var(--neutral-colors-white);
    font-size: var(--font-size-base);
    font-weight: bold;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
  }
  
  .submitButton:hover {
    background-color: #ff4500;
  }
  
  .closeButton {
    position: absolute;
    top: var(--padding-xs);
    right: var(--padding-xs);
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  /* Existing styles */

  .createButton {
    padding: var(--padding-xs) var(--padding-lg);
    border-radius: var(--br-21xl);
    background-color: var(--color-orangered);
    color: var(--neutral-colors-white);
    font-size: var(--font-size-base);
    font-weight: bold;
    border: none;
    cursor: pointer;
  }
  
  .eventList {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: var(--gap-5xs);
  }
  
  .eventCard {
    display: flex;
    flex-direction: column;
    background-color: #eef5ff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
  }
  
  .eventCard:hover {
    transform: translateY(-5px);
  }
  
  .eventImage {
    width: 100%;
    height: 200px;
    overflow: hidden;
  }
  
  .eventImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .eventDetails {
    padding: 20px;
  }
  
  .eventDetails h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #333333;
  }
  
  .eventDetails p {
    font-size: 16px;
    margin-bottom: 5px;
    color: #666666;
  }
  
  .cardButtons {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
  }
  
  .updateButton,
  .joinButton,
  .deleteButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .updateButton img,
  .deleteButton img {
    width: 20px;
    height: 20px;
  }
  
  .joinButton {
    background-color: #007bff;
    color: #ffffff;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
  }

  .feedbackButton{
    background-color: transparent;
    border: none;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    padding: 8px 16px;
    border-radius: 4px;
    padding: var(--padding-xs) var(--padding-lg);
    font-size: var(--font-size-base);
    font-weight: bold;
    color: var(--neutral-colors-white);
  }
  
  .updateButton img,
  .deleteButton img {
    width: 20px;
    height: 20px;
  }
  
  .joinButton 
  {
    background-color: #007bff;
    color: #fff;
    padding: 8px 16px;
    border-radius: 4px;
  }
  .dropDown{
    border: 1px solid var(--color-gray-100);  border-radius: var(--br-4xs);
    font-size: var(--font-size-base);
    color: var(--color-gray-200);
  }
  .searchButton {
    padding: var(--padding-xs) var(--padding-lg);
    border-radius: var(--br-21xl);
    /* background-color: var(--color-blue);
    color: var(--neutral-colors-white); */
    font-size: var(--font-size-base);
    font-weight: bold;
    border: none;
    cursor: pointer;
    margin-right: var(--padding-xs);
  }