.signup_container {
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup_form_container {
  width: 770px;
  height: 600px;
  display: flex;
  border-radius: 10px;
  box-shadow:
    0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%),
    0px 1px 8px 0px rgb(0 0 0 / 12%);
}

.left {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-gray-200);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.left h1 {
  margin-top: 0;
  color: white;
  font-size: 35px;
  align-self: center;
  margin-left: 3rem;
}

.right {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.form_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form_container h1 {
  font-size: 40px;
  margin-top: 0;
}

.input {
  outline: none;
  border: none;
  width: 80%;
  padding: 15px;
  border-radius: 10px;
  background-color: #ffeae0;
  margin: 5px 0;
  font-size: 14px;
}
.dropDown{
  outline: none;
  border: none;
  width: 90%;
  padding: 15px;
  border-radius: 10px;
  background-color: #ffeae0;
  margin: 5px 0;
  font-size: 14px;
}

.error_msg {
  width: 80%;
  padding: 15px;
  margin: 5px 0;
  font-size: 14px;
  background-color: #f34646;
  color: white;
  border-radius: 5px;
  text-align: center;
}

.white_btn,
.green_btn {
  border: none;
  outline: none;
  padding: 12px 0;
  background-color: white;
  border-radius: 20px;
  width: 80%;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}
.white_btn {
  width: 100px;
  margin: 10px 0;
}
.green_btn {
  background-color: #FF5F0F;
  color: white;
  margin: 10px;
}

@media screen and (max-width: 768px) {
  .input {
    padding: 12px;
    font-size: 13px;
  }
  .form_container h1,
  .left h1 {
    font-size: 30px;
  }

  .signup_form_container {
    width: 90%;
  }

  .left {
    flex: 1;
  }

  .right {
    flex: 2;
  }
}

@media screen and (max-width: 480px) {
  .input {
    padding: 10px;
    font-size: 12px;
  }
  .form_container h1,
  .left h1 {
    font-size: 25px;
  }

  .signup_form_container {
    width: 100%;
  }

  .left {
    flex: 1;
  }

  .right {
    flex: 2;
  }
}
